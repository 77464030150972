"use client";

import {
  Box,
  Burger,
  Button,
  Container,
  Group,
  Indicator,
  IndicatorProps,
  Paper,
  Stack,
  Text,
  Transition,
} from "@mantine/core";
import {
  IconCalendarEvent,
  IconChevronRight,
  IconLogin,
  IconShield,
  IconShoppingCart,
  IconTicket,
  IconUserCircle,
} from "@tabler/icons-react";
import { User } from "firebase/auth";
import Link from "next/link";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Translate, translate } from "react-i18nify";
import { useUser } from "reactfire";
import { KiwiTicketLogo } from "~/components/KiwiTicketLogo";
import { Ticket } from "~/interfaces";
import classes from "./Header.module.css";
import { api } from "~/trpc/react";

type HeaderLink = {
  icon?: ReactNode;
  href: string;
  label: string;
  testid?: string;
  className?: string;
  indicatorProps?: Partial<IndicatorProps>;
};

const generateLinks = ({
  authUser,
  cartItems,
  isAdmin,
}: {
  authUser?: User | null;
  cartItems?: Ticket[];
  isAdmin?: boolean;
}) => {
  const isSignedIn = Boolean(authUser);
  const links: HeaderLink[] = [
    {
      label: translate("header.events"),
      href: "/events",
      icon: <IconCalendarEvent />,
      testid: "nav-events",
    },
    {
      label: translate("header.cart"),
      href: "/cart",
      icon: <IconShoppingCart />,
      testid: "nav-cart",
      indicatorProps: cartItems?.length
        ? {
            color: "red",
            label: cartItems.length,
            size: 20,
            offset: 6,
            position: "middle-end",
          }
        : undefined,
    },
  ];

  if (isSignedIn) {
    links.push({
      label: translate("header.tickets"),
      href: "/tickets",
      icon: <IconTicket />,
      testid: "nav-tickets",
    });
  }

  links.push(
    isSignedIn
      ? {
          label: translate(authUser?.isAnonymous ? "account.guest_label" : "account.account_label"),
          href: "/account",
          icon: <IconUserCircle />,
          testid: "nav-account",
          className: "client-init",
        }
      : {
          label: translate("auth.sign_in"),
          href: "/sign-in",
          testid: "nav-sign-in",
          icon: <IconLogin />,
        },
  );

  if (isAdmin)
    links.push({
      label: translate("admin.name"),
      href: "/admin",
      testid: "nav-admin",
      icon: <IconShield />,
    });

  return links;
};

const Header = () => {
  const user = useUser();
  const authUser = user.data;
  const [opened, setToggleOpened] = useState(false);

  const { data: userRoles, refetch: refetchUserRoles } = api.account.roles.useQuery(undefined, {
    enabled: Boolean(user),
  });
  const { data: cartItems, refetch: refetchCartItems } = api.cart.items.useQuery(undefined, {
    enabled: Boolean(user),
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: refetch on user change
  useEffect(() => {
    refetchUserRoles();
    refetchCartItems();
  }, [refetchCartItems, authUser?.uid]);

  const wrapWithIndicator = useCallback((link: HeaderLink, item: ReactNode) => {
    if (link.indicatorProps) {
      return (
        <Indicator
          {...link.indicatorProps}
          className={classes.indicator}
          data-testid={`${link.testid}-indicator`}
        >
          {item}
        </Indicator>
      );
    }
    return item;
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: update on user change
  const navItems = useMemo(
    () =>
      generateLinks({ authUser, cartItems, isAdmin: userRoles?.isAdmin }).map((link) => (
        <Link
          key={`${link.label}-${link.href}-${link.testid}`}
          href={link.href}
          passHref
          className={classes.link}
          data-testid={link.testid}
          onClick={() => setToggleOpened(false)}
        >
          {wrapWithIndicator(
            link,
            <Group gap={4} pr={link.indicatorProps ? 24 : 0}>
              {link.icon}
              {link.label}
            </Group>,
          )}
        </Link>
      )),
    [user.status, authUser, cartItems, userRoles?.isAdmin, wrapWithIndicator],
  );

  const sellTicketsOrProducerPortalButton = userRoles?.isProducer ? (
    <Button
      component={Link}
      href="/producer"
      radius="xl"
      color="dark"
      className={classes.action}
      size="xs"
      data-testid="producer-portal-button"
    >
      <Text size="sm">
        <Translate value={"header.producer_portal"} />
      </Text>
      <IconChevronRight size={18} style={{ marginLeft: 2, marginRight: -6 }} />
    </Button>
  ) : (
    <Button
      component={Link}
      href="/sell"
      radius="xl"
      color="dark"
      className={classes.action}
      size="xs"
      data-testid="sell-tickets-button"
    >
      <Translate value={"header.sell_tickets"} />
    </Button>
  );

  return (
    <Box component="header" className={classes.header}>
      <Container className={classes.inner} fluid>
        <Group gap={5} className={classes.title}>
          <Burger
            color="black"
            opened={opened}
            onClick={() => setToggleOpened(!opened)}
            className={classes.burger}
            data-testid={"MenuButton"}
            size="sm"
          />

          <Link href={"/"} className={classes.link}>
            <Group gap={8}>
              <KiwiTicketLogo size={28} />
              <Text size="lg">
                <Translate value="application.name" />
              </Text>
            </Group>
          </Link>
        </Group>

        <Group gap={4} className={classes.links}>
          {navItems}
        </Group>

        <Transition transition="scale-y" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} style={styles} data-testid={"Navbar--mobileMenu"}>
              <Stack p={8} gap={4}>
                {navItems}
              </Stack>
            </Paper>
          )}
        </Transition>

        {sellTicketsOrProducerPortalButton}
      </Container>
    </Box>
  );
};

export default Header;
